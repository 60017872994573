import store from '../store'
import Vue from 'vue'

export default [
  {
    path: '/s/:slug',
    component: () =>
      import(/* webpackChunkName: "share-view" */ '@/views/Share.vue'),
    meta: {
      isAuthenticated: false
    },
    children: [
      {
        path: '', // Empty path for the named route
        name: 'share_root',
        redirect: 'details',
        meta: {
          isAuthenticated: false
        }
      },
      {
        path: 'details',
        name: 'share_details',
        props: true,
        meta: {
          isAuthenticated: false
        },
        component: () =>
          import(
            /* webpackChunkName: "share-view" */ '@/views/share/ShareViewDetails.vue'
          ),
        beforeEnter: (to, from, next) => {
          store.dispatch('app/loadAppConfig')
          if (store.getters['shared/getSharedBySlug'](to.params.slug)) {
            next()
          }
          store
            .dispatch('shared/loadShare', { slug: to.params.slug })
            .then((share) => {
              next()
            })
            .catch((error) => {
              if (error.status === 403 || error.status === 401) {
                store.dispatch('shared/handleForbiddenError', {
                  shareSlug: to.params.slug
                })
                Vue.notify({
                  group: 'error',
                  type: 'error',
                  data: {
                    title: this.$t('notifications.sharing.share-auth-changed'),
                    text: this.$t('notifications.sharing.re-enter-password')
                  }
                })
                next()
              } else {
                store.commit('shared/resetState')
                next({
                  name: 'share_error',
                  params: { error: error, slug: to.params.slug }
                })
              }
            })
        }
      },
      {
        path: 'folder/:uuid',
        name: 'share_content_folder',
        meta: {
          isAuthenticated: false
        },
        component: () =>
          import(
            /* webpackChunkName: "share-view" */ '@views/share/ShareViewExplorer.vue'
          ),
        beforeEnter: (to, from, next) => {
          // If we try to access folder directly without loading share, push user to /details
          if (from.name === null) {
            next({
              name: 'share_details',
              params: { slug: to.params.slug }
            })
          }
          const assetId = to.params.uuid
          store.dispatch('assets/setActive', { assetId: assetId })

          store
            .dispatch('shared/loadShareContentFolder', {
              assetId: assetId
            })
            .then(() => {
              next()
            })
            .catch((error) => {
              if (error.status === 403 || error.status === 401) {
                store.dispatch('shared/handleForbiddenError', {
                  shareSlug: to.params.slug
                })
                next({
                  name: 'share_details',
                  params: { slug: to.params.slug }
                })
                Vue.notify({
                  group: 'error',
                  type: 'error',
                  data: {
                    title: this.$t('notifications.sharing.share-auth-changed'),
                    text: this.$t('notifications.sharing.re-enter-password')
                  }
                })
              } else {
                store.commit('shared/resetState')
                next({
                  name: 'share_error',
                  params: { error: error, slug: to.params.slug }
                })
              }
            })
        }
      },
      {
        path: 'asset/:uuid',
        name: 'share_content_asset',
        meta: {
          isAuthenticated: false
        },
        component: () =>
          import(
            /* webpackChunkName: "share-view" */ '@views/share/ShareViewAsset.vue'
          ),
        beforeEnter: (to, from, next) => {
          // If we try to access asset directly without loading share, push user to /details
          if (from.name === null) {
            next({
              name: 'share_details',
              params: { slug: to.params.slug }
            })
          }
          const assetId = to.params.uuid
          store.dispatch('shared/setActive', { assetId: assetId })

          store
            .dispatch('shared/loadShareContentAsset', {
              assetId: assetId
            })
            .then(() => {
              next()
            })
            .catch((error) => {
              if (error.status === 403) {
                store.dispatch('shared/handleForbiddenError', {
                  shareSlug: to.params.slug
                })
                next({
                  name: 'share_details',
                  params: { slug: to.params.slug }
                })
                Vue.notify({
                  group: 'error',
                  type: 'error',
                  data: {
                    title: this.$t('notifications.sharing.share-auth-changed'),
                    text: this.$t('notifications.sharing.re-enter-password')
                  }
                })
              } else {
                store.commit('shared/resetState')
                next({
                  name: 'share_error',
                  params: { error: error, slug: to.params.slug }
                })
              }
            })
        }
      },
      {
        path: 'error',
        name: 'share_error',
        props: true,
        meta: {
          isAuthenticated: false
        },
        component: () =>
          import(
            /* webpackChunkName: "share-view" */ '@views/share/ShareViewError.vue'
          ),
        beforeEnter(to, from, next) {
          // Since error does not get cached/stored we want to try and push the user back
          // to share_details if they navigate directly/refresh the page
          if (!to.params.error) {
            next({
              name: 'share_details',
              params: { slug: to.params.slug }
            })
          } else {
            next()
          }
        }
      }
    ]
  }
]
