import store from '../store'

export default [
  {
    path: '/embed/:slug',
    component: () =>
      import(/* webpackChunkName: "project" */ '@/views/EmbedProject.vue'),
    beforeEnter: (to, from, next) => {
      // store.dispatch('setActiveTeam', { team: null })
      store
        .dispatch('project/loadProject', { projectId: to.params.slug })
        .then(() => {
          let project = store.getters['project/project']

          if (project === null) {
            const permissionError =
              store.getters['project/loadedPermissionError']

            if (permissionError) {
              // If the current route is not the permission error route,
              // redirect to the permission error route
              if (to.name !== '403-Drive') {
                next({ name: '403-Drive', params: { slug: to.params.slug } })
                return
              } else {
                next()
                return
              }
            }

            next('/missing-drive')
          }

          store
            .dispatch('getTeam', { teamId: project.team_slug })
            .then((team) => {
              store.dispatch('setActiveTeam', { team })
            })

          let params = {}
          params.project_id = project.id
          store.dispatch('project/updateLastAccessed', { params: params })

          // BUG: A second call to the root's children is called
          // after adding this, appears to be because the tree is
          // fetching it as well
          // Removing this will fail to load current folder on refresh, seems to be race condition
          store.dispatch('assets/loadRootFolder', {
            project: project
          })

          // Direct the user to the entry folder if it exists (for guests)
          if (
            from.path === '/' &&
            project.entry_folder_uuid &&
            to.params.uuid === undefined
          ) {
            if (
              from.path === to.path ||
              to.params.stop_infinite_loop === 'true'
            ) {
              // As we are redirecting to a child of this route, we
              // need to flag if we are redirecting to itself, so we
              // don't get stuck in an infinite loop (or if we are
              // redirecting to ourselves, we need to stop the loop too)
              next()
              return
            }

            next({
              name: 'embed_content_folder',
              params: {
                uuid: to.params.uuid
                  ? to.params.uuid
                  : project.entry_folder_uuid,
                slug: to.params.slug,
                stop_infinite_loop: 'true'
              },
              query: to.query
            })
            return
          }
          next()
        })
    },
    children: [
      {
        path: '',
        redirect: 'content'
      },
      {
        path: 'content',
        name: 'embed_content_base',
        component: () =>
          import(
            /* webpackChunkName: "project-content" */ '@/views/project/EmbedContent.vue'
          ),
        beforeEnter: (to, from, next) => {
          let project = store.getters['project/project']

          store.dispatch('assets/loadRootFolder', { project: project })
          next()
        }
      },
      {
        path: 'content/folder/:uuid',
        name: 'embed_content_folder',
        component: () =>
          import(
            /* webpackChunkName: "project-content" */ '@/views/project/EmbedContent.vue'
          ),
        beforeEnter: (to, from, next) => {
          store.dispatch('assets/loadFolder', { folderId: to.params.uuid })
          next()
        }
      },
      {
        path: 'content/:uuid',
        name: 'embed_content_view',
        component: () =>
          import(
            /* webpackChunkName: "project-view" */ '@/views/project/EmbedView.vue'
          ),
        beforeEnter: (to, from, next) => {
          store
            .dispatch('assets/loadAsset', {
              id: to.params.uuid,
              related: true,
              force: true
            })
            .then(() => {
              store.dispatch('shares/loadShareForAsset', {
                assetId: to.params.uuid
              })
              next()
            })
            .catch(() => {
              store.dispatch('assets/setLoaded')
              next()
            })
        }
      }
    ]
  },
  {
    path: '/p/:slug',
    meta: {
      canSetAsFirst: true
    },
    component: () =>
      import(/* webpackChunkName: "project" */ '@/views/Project.vue'),
    beforeEnter: (to, from, next) => {
      const needsOnboarding = store.getters['doesUserNeedOnboarding']
      if (needsOnboarding) {
        next({ name: 'user-onboarding' })
        return
      }

      store.dispatch('fileTypes/fetchSystemSupportedFileTypes')

      // store.dispatch('setActiveTeam', { team: null })
      store
        .dispatch('project/loadProject', { projectId: to.params.slug })
        .then(() => {
          let project = store.getters['project/project']

          if (project === null) {
            const permissionError =
              store.getters['project/loadedPermissionError']

            if (permissionError) {
              // If the current route is not the permission error route,
              // redirect to the permission error route
              if (to.name !== '403-Drive') {
                next({ name: '403-Drive', params: { slug: to.params.slug } })
                return
              } else {
                next()
                return
              }
            }

            next('/missing-drive')
          }

          store
            .dispatch('getTeam', { teamId: project.team_slug })
            .then((team) => {
              store.dispatch('setActiveTeam', { team })
            })

          let params = {}
          params.project_id = project.id
          store.dispatch('project/updateLastAccessed', { params: params })

          // BUG: A second call to the root's children is called
          // after adding this, appears to be because the tree is
          // fetching it as well
          // Removing this will fail to load current folder on refresh, seems to be race condition
          store.dispatch('assets/loadRootFolder', {
            project: project
          })

          // Direct the user to the entry folder if it exists (for guests)
          if (
            from.path === '/' &&
            project.entry_folder_uuid &&
            to.params.uuid === undefined
          ) {
            if (
              from.path === to.path ||
              to.params.stop_infinite_loop === 'true'
            ) {
              // As we are redirecting to a child of this route, we
              // need to flag if we are redirecting to itself, so we
              // don't get stuck in an infinite loop (or if we are
              // redirecting to ourselves, we need to stop the loop too)
              next()
              return
            }

            next({
              name: 'content_folder',
              params: {
                uuid: to.params.uuid
                  ? to.params.uuid
                  : project.entry_folder_uuid,
                slug: to.params.slug,
                stop_infinite_loop: 'true'
              }
            })
            return
          }

          next()
        })
    },
    children: [
      {
        path: '',
        redirect: 'content'
      },
      {
        path: 'content',
        name: 'content_base',
        meta: {
          canSetAsFirst: true
        },
        component: () =>
          import(
            /* webpackChunkName: "project-content" */ '@/views/project/Content.vue'
          ),
        beforeEnter: (to, from, next) => {
          let project = store.getters['project/project']

          store.dispatch('assets/loadRootFolder', {
            project: project,
            forceActive: true
          })
          next()
        }
      },
      {
        path: 'content/folder/:uuid',
        name: 'content_folder',
        meta: {
          canSetAsFirst: true
        },
        component: () =>
          import(
            /* webpackChunkName: "project-content" */ '@/views/project/Content.vue'
          ),
        beforeEnter: (to, from, next) => {
          store
            .dispatch('assets/loadFolder', { folderId: to.params.uuid })
            .then(() => {
              next()
            })
            .catch(() => {
              next('/')
            })
        }
      },
      {
        path: 'content/search/:uuid',
        name: 'content_search',
        component: () =>
          import(
            /* webpackChunkName: "project-content" */ '@/views/project/Content.vue'
          ),
        beforeEnter: (to, from, next) => {
          store.dispatch('assets/loadFolder', { folderId: to.params.uuid })
          next()
        }
      },
      {
        path: 'content/:uuid',
        name: 'content_view',
        component: () =>
          import(
            /* webpackChunkName: "project-view" */ '@/views/project/View.vue'
          ),
        beforeEnter: (to, from, next) => {
          store
            .dispatch('assets/loadAsset', {
              id: to.params.uuid,
              related: true,
              force: true
            })
            .then(() => {
              store.dispatch('shares/loadShareForAsset', {
                assetId: to.params.uuid
              })
              next()
            })
            .catch(() => {
              store.dispatch('assets/setLoaded')
              next()
            })
        }
      },
      {
        path: 'tags',
        name: 'project_tags',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "project-settings" */ '@/views/project/Tags.vue'
          ),
        beforeEnter: (to, from, next) => {
          let project = store.getters['project/project']
          store.dispatch('tags/loadProjectTags', { projectId: project.id })
          to.params.project = project
          next()
        }
      },

      {
        path: 'no-access',
        name: '403-Drive',
        component: () =>
          import(
            /* webpackChunkName: "permission-error" */ '@views/ProjectPermissionError.vue'
          )
      }
    ]
  }
]
