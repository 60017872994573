/*
  Projects List module
  ---
  This is the v2 of the 'projects' module
  This will contain general projects storage
  All single project information will be in the project module
  The Projects List module is simple for list views and aggregation

  Currently this information is mixed together with lists of projects
  and the DAG. This quickly becomes an unmaintanable mess.

*/
import * as api from '@utils/api.js'
import filter from 'lodash/filter'
import split from 'lodash/split'
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import union from 'lodash/union'
import orderBy from 'lodash/orderBy'
import { updateInListByKey } from '@utils/misc.js'

const state = {
  data: [],
  seen: [],
  filtered: [],
  selectedPos: null,

  loadedOn: null,
  hasMore: false,
  totalRecords: null,
  loaded: false,
  loading: false,
  loadingError: false,

  nextPageLink: null,
  prevPageLink: null,

  areFiltered: false,
  filtersActive: null,
  filtersDefaults: {
    teamId: '-any-',
    sortBy: 'newest',
    query: '',
    status: '-any-'
  }
}

const getters = {
  projects(state) {
    return state.data
  },
  hasMore(state) {
    return state.hasMore
  },
  totalRecords(state) {
    return state.totalRecords
  },
  nextPageLink(state) {
    return state.nextPageLink
  },
  prevPageLink(state) {
    return state.prevPageLink
  },
  loading(state) {
    return state.loading
  },
  loaded(state) {
    return state.loaded
  },
  loadingError(state) {
    return state.loadingError
  },
  loadedAll(state) {
    return state.loaded && state.hasMore === false
  },
  selected(state) {
    if (state.selectedPos === null || state.filtered === null) return null
    return state.filtered[state.selectedPos]
  },
  areFiltered(state) {
    return state.areFiltered
  },
  filtersActive(state) {
    return state.filtersActive
  },
  filtered(state) {
    if (state.areFiltered) return state.filtered
    return state.data
  },
  filtersDefaults(state) {
    return state.filtersDefaults
  },

  getProjectById: (state) => (id) => {
    let found = state.data.find((row) => {
      return row.id === id || row.slug === id
    })

    if (found === undefined) return null
    return found
  }
}

const mutations = {
  removeProject(state, projectId) {
    // Also remove from seen
    var i = state.data.findIndex((row) => row.id === projectId)
    if (i > -1) state.data.splice(i, 1)

    var j = state.seen.findIndex((row) => row === projectId)
    if (j > -1) state.seen.splice(j, 1)
  },

  setLoading(state) {
    state.loading = true
    state.loadingError = false
  },
  setLoaded(state) {
    state.loading = false
    state.loadingError = false
    state.loaded = true
    state.loadedOn = new Date()
  },
  setLoadingError(state) {
    state.loading = false
    state.loadingError = true
  },
  setProjectCover(state, { id, cover }) {
    for (let index = 0; index < state.data.length; index++) {
      if (state.data[index].id === id) {
        state.data[index].cover = cover
      }
    }
  },
  addData(state, data) {
    state.data = []
    data.forEach((project) => {
      state.data = updateInListByKey(state.data, 'id', project.id, project)
      if (!state.seen.includes(project.id)) {
        state.seen.push(project.id)
      }
    })
  },

  addProject(state, project) {
    state.data = updateInListByKey(state.data, 'id', project.id, project)
    if (!state.seen.includes(project.id)) {
      state.seen.push(project.id)
    }
  },

  setHasMore(state, more) {
    state.hasMore = more
  },
  setTotalRecords(state, total) {
    state.totalRecords = total
  },
  setNextPageLink(state, url) {
    state.nextPageLink = url
  },
  setPrevPageLink(state, url) {
    state.prevPageLink = url
  },
  selectNextFiltered(state) {
    let length = 0

    if (state.filtered !== null) {
      length = state.filtered.length
    }

    if (state.selectedPos === null) {
      state.selectedPos = 0
    } else {
      state.selectedPos = state.selectedPos + 1
    }

    if (state.selectedPos >= length) {
      state.selectedPos = length - 1
    }
  },
  selectPrevFiltered(state) {
    if (state.selectedPos === null) {
      state.selectedPos = 0
    } else {
      state.selectedPos = state.selectedPos - 1
    }

    if (state.selectedPos < 0) {
      state.selectedPos = 0
    }
  },

  clear(state) {
    state.data = []
    state.seen = []
    state.hasMore = false
    state.loaded = false
    state.loading = false
    state.loadingError = false
    state.selectedPos = null
  },

  resetFilters(state) {
    state.filtered = state.data
    state.filtersActive = null
    state.areFiltered = false
    state.selectedPos = null
  },

  filter(state, { teamId, sortBy, query, status }) {
    let subset = state.data

    let setTeamId = state.filtersDefaults.teamId
    let setSortBy = state.filtersDefaults.sortBy
    let setQuery = state.filtersDefaults.query
    let setStatus = state.filtersDefaults.status

    if (teamId !== setTeamId) {
      state.areFiltered = true
    }
    if (sortBy !== setSortBy) {
      state.areFiltered = true
    }
    if (query !== setQuery) {
      state.areFiltered = true
    }
    if (status !== setStatus) {
      state.areFiltered = true
    }

    if (teamId !== '' && teamId !== '-any-') {
      subset = filter(subset, function (project) {
        return String(project.team_id) === String(teamId)
      })
      setTeamId = teamId
    }

    if (query !== '') {
      let parts = split(query, ' ')

      let subA = []
      forEach(parts, function (part) {
        if (part !== '') {
          let a = filter(subset, function (item) {
            return includes(item.title.toLowerCase(), part.toLowerCase())
          })
          subA = union(subA, a)
        }
      })

      subset = subA
      setQuery = query
    }

    if (sortBy !== '') {
      if (sortBy === 'newest') {
        subset = orderBy(subset, ['created_at'], ['desc'])
      }
      if (sortBy === 'oldest') {
        subset = orderBy(subset, ['created_at'], ['asc'])
      }
      if (sortBy === 'alpha') {
        // because orderBy is not case-insensitive
        subset = orderBy(subset, [(item) => item.title.toLowerCase()], ['asc'])
      }
      setSortBy = sortBy
    }

    if (status !== '') {
      subset = filter(subset, function (project) {
        switch (status) {
          case 'demo':
            return project.status === 'demo'
          case 'locked':
            return project.status === 'locked'
          case 'archived':
            return project.status === 'archived'
          case 'active':
            return project.status === 'active'
          default:
            // Don't show archived items in the 'any' list
            return project.status !== 'archived'
        }
      })

      setStatus = status
    }

    state.filtered = subset
    state.filtersActive = {
      teamId: setTeamId,
      sortBy: setSortBy,
      query: setQuery,
      status: setStatus
    }
  }
}

const actions = {
  updateCover({ commit }, data) {
    return api.updateProjectCover(data).then((response) => {
      commit('addProject', response.data)
    })
  },

  removeCover({ commit }, { id }) {
    return api.removeProjectCover(id).then((response) => {
      commit('addProject', response.data)
    })
  },

  archive({ commit }, { projectId }) {
    return api
      .archiveProject(projectId)
      .then(() => {
        // Remove from the project list
        commit('removeProject', projectId)
        return true
      })
      .catch(() => {
        return false
      })
  },

  unarchive({ commit }, { projectId }) {
    return api
      .unarchiveProject(projectId)
      .then((response) => {
        let data = []
        data[0] = response.data
        commit('addData', data)
        return true
      })
      .catch(() => {
        return false
      })
  },

  updateScreenshot({ commit }, { data }) {
    let id = data.project_id
    let cover = data.cover
    commit('setProjectCover', { id, cover })
  },

  clear({ commit }) {
    commit('clear')
  },

  reload({ dispatch }) {
    dispatch('clear').then(() => {
      dispatch('loadProjects')
    })
  },

  softLoad({ state, dispatch }) {
    let yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1) // Only keep the list for a day

    if (state.loadedOn === null || state.loadedOn < yesterday) {
      dispatch('reload')
    } else {
      if (state.loading === false && state.loaded === false) {
        dispatch('loadProjects')
      }
    }
  },

  loadProjectsForTeam({ commit, dispatch }, { teamId }) {
    // Base Api call
    let uri = 'api/projects?team_id=' + teamId

    commit('setLoading')
    return api
      .rawApiGetCall(uri)
      .then((response) => {
        dispatch('handleReturn', response.data)
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  loadProjects({ commit, dispatch }) {
    // Base Api call
    let uri = 'api/projects'

    commit('setLoading')
    return api
      .rawApiGetCall(uri)
      .then((response) => {
        dispatch('handleReturn', response.data)
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  loadNext({ dispatch, state }) {
    let uri = state.nextPageLink
    return api.rawApiGetCall(uri).then((response) => {
      dispatch('handleReturn', response.data)
    })
  },

  setLoading({ commit, state }) {
    commit('setLoading', state)
  },

  handleReturn({ commit, dispatch }, data) {
    let dataCleaned = []
    data.data.forEach((row) => {
      dataCleaned.push(row.data)
    })

    let hasMore = false
    data.pagination.links.forEach((row) => {
      if (row.rel === 'pagination.next') {
        commit('setNextPageLink', row.url)
        hasMore = true
      }
      if (row.rel === 'pagination.previous') {
        commit('setPrevPageLink', row.url)
      }
    })

    let totalRecords = data.pagination.total_count

    commit('setHasMore', hasMore)
    commit('addData', dataCleaned)
    commit('setTotalRecords', totalRecords)

    if (hasMore) {
      dispatch('loadNext')
    } else {
      commit('setLoaded')
    }
  },

  filter({ commit, state }, { teamId, sortBy, query, status }) {
    commit('filter', { state, teamId, sortBy, query, status })
  },

  resetFilters({ commit, state }) {
    commit('resetFilters', { state })
  },

  selectNextFiltered({ commit, state }) {
    commit('selectNextFiltered', { state })
  },

  selectPrevFiltered({ commit, state }) {
    commit('selectPrevFiltered', { state })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
