import orderBy from 'lodash/orderBy'

import { cloneDeep } from 'lodash'
import PrevizDirectUpload from '@/libs/direct-upload.js'

import * as api from '@utils/api.js'
import {
  findInListByKey,
  updateInListByKey,
  findManyInListByKey
} from '@utils/misc.js'
import {
  generateFolderTreeStructure,
  getAllChildrenForFolderId,
  getAssetType,
  walkFolderTree
} from '@utils/assetUtils'

import assetConfig from '@src/config/assetConfig'

import FolderType from '@src/enums/folderType'
import UploadType from '@src/enums/uploadType'
import UploadState from '@src/enums/uploadState'
import AssetConflictAction from '@src/enums/assetConflictAction'

const state = function () {
  return {
    active: null,
    folderTreeSelected: null,
    currentDragAsset: null,

    folderTree: {},
    folders: [],
    smartFolders: [],
    searchFolders: [],
    items: [],
    expanded: [],
    resources: [],

    filterItems: [],
    filterTerm: null,

    seen: [],
    expandedSeen: [],

    hasMore: false,
    totalRecords: null,
    loaded: false,
    loading: false,
    loadingError: false,

    nextPageLink: null,
    prevPageLink: null,
    childrenResponse: {},

    uploading: [],
    uploaded: [],
    uploadTrayOpen: true // needs to be reverse to work
  }
}

const getters = {
  active(state) {
    return state.active ? state.active : ''
  },
  isActiveSmartFolder(state, getters) {
    return (
      findInListByKey(getters.getSmartFolders, 'id', getters.active) != null
    )
  },
  isActiveSearchFolder(state, getters) {
    return (
      findInListByKey(getters.getSearchFolders, 'id', getters.active) != null
    )
  },
  activeSet: (state) => (id) => {
    if (state.active === null) return null

    let active = findInListByKey(state.folders, 'id', state.active)

    if (active !== null) {
      if (active.project_id !== undefined && active.project_id === id)
        return active
    }
    return null
  },

  getFolderTreeSelected(state) {
    return state.folderTreeSelected
  },
  // Smart folders
  getSmartFolders(state) {
    return state.smartFolders
  },

  getSmartFolder: (state) => (id) => {
    return findInListByKey(state.smartFolders, 'id', id)
  },

  /**
   * Returns the current set of smart folders for an asset
   * or creates a new set if it doesn't exist
   */

  getSmartFoldersForAsset: (state, getters) => (id) => {
    const asset = getters.getAssetSimpleById(id)

    return asset && asset.smart_folders !== undefined
      ? asset.smart_folders
      : new Set()
  },

  getSearchFoldersForAsset: (state, getters) => (id) => {
    const asset = getters.getAssetSimpleById(id)

    return asset && asset.search_folder !== undefined
      ? asset.search_folder
      : new Set()
  },

  // Search Folders
  getSearchFolders(state) {
    return state.searchFolders
  },

  getSearchFolder: (state) => (id) => {
    return findInListByKey(state.searchFolders, 'id', id)
  },

  /**
   * Returns the selected folder's full information from the state
   * @param {String} projectId - id of the project, used to validate the folder
   * @returns {Object} - folder object
   */
  getFolderTreeSelectedSet: (state) => (projectId) => {
    if (state.folderTreeSelected === null) return null

    const folderTreeSelected = findInListByKey(
      state.folders,
      'id',
      state.folderTreeSelected
    )

    if (
      folderTreeSelected !== null &&
      folderTreeSelected.project_id !== undefined &&
      folderTreeSelected.project_id === projectId
    )
      return folderTreeSelected

    // If the folder hasn't been loaded yet, just return the id
    return {
      id: state.folderTreeSelected
    }
  },

  getTreeForFolderId: (state) => (id) => {
    let ret = []

    function getParent(parentId) {
      let item = findInListByKey(state.folders, 'id', parentId)
      if (item) {
        let arr = item

        ret.push(arr)
        if (arr.parent) {
          getParent(arr.parent)
        }
      }
    }

    let activeFolder = findInListByKey(state.folders, 'id', id)

    if (activeFolder) {
      ret.push(activeFolder)
      let parentId = activeFolder.parent
      if (parentId !== null) {
        getParent(parentId)
      }
    }

    return ret.reverse()
  },

  getFolderInTreeForId: (state) => (folderId) => {
    const folderTree = state.folderTree
    return walkFolderTree({
      folderTree,
      folderId,
      returnItem: FolderType.FOLDER
    })
  },

  getFolderParentInTreeForId: (state) => (folderId) => {
    const folderTree = state.folderTree
    return walkFolderTree({
      folderTree,
      folderId,
      returnItem: FolderType.PARENT
    })
  },

  getFolderChildrenInTreeForId: (state) => (folderId) => {
    return getAllChildrenForFolderId(state.folderTree, folderId)
  },

  getCurrentDragAsset(state) {
    return state.currentDragAsset
  },

  loadedSets(state) {
    let ret = []
    state.folders.forEach((set) => {
      ret.push({
        id: set.id,
        child_count: set.child_count,
        name: set.name,
        parent: set.parent,
        is_root: set.is_root
      })
    })
    return ret
  },
  rawData(state) {
    return state.folders
  },
  folderTree(state) {
    return state.folderTree
  },

  /**
   * Returns the folder for the given folder id
   * @param {String} id - The folder id
   * @returns {Object} - The found folder or an empty object
   */
  getFolderById: (state) => (id) => {
    const folder = state.folders.find(function (item) {
      return item.id === id
    })
    return typeof folder === 'object' ? folder : {}
  },

  /**
   * Returns all folders that have the given parent id
   * @param {String} parentId - The parent folder id to search for
   * @returns {Array} - The found folders
   */
  getFoldersByParentId: (state) => (parentId) => {
    const folders = state.folders.filter(function (item) {
      return item.parent === parentId
    })
    return folders
  },
  rootFolder(state) {
    // When we are in the folder, there will be an active folder,
    // so we can (and should) use that to get the root folder.
    // If we don't have an active folder, that means the user navigated
    // directly to the asset, so there should only be one expanded item,
    // so we can use that to get the current project id
    const currentProjectId = state.active
      ? findInListByKey(state.folders, 'id', state.active).project_id
      : (state.expanded.length === 1 && state.expanded[0].project_id) || null

    return state.folders.find(function (item) {
      return item.is_root === true && item.project_id === currentProjectId
    })
  },
  assets(state, getters) {
    if (state.active === null) return []
    const folder = state.active

    // Returns differently based on wether the asset is a smart folder or not
    // Smart folders get returned based on the asset's smart_folder set,
    // where as normal folders get returned based on the asset's parent folder
    if (getters.getSmartFolder(folder) !== null) {
      return state.items.filter((item) => {
        return item.smart_folders && item.smart_folders.has(folder)
      })
    } else if (getters.getSearchFolder(folder) !== null) {
      return state.items.filter((item) => {
        return item.search_folders && item.search_folders.has(folder)
      })
    } else {
      const items = state.items.filter(function (item) {
        return (
          item.type !== 'smart_folder' &&
          item.type !== 'search_folder' &&
          item.parent_id === folder &&
          item.deleted_at === null
        )
      })

      const uploading = state.uploading.filter(function (item) {
        return item.parent_id === folder && item.uploadState !== 'completed'
      })
      return [...uploading, ...items]
    }
  },
  hasMore(state) {
    return state.hasMore
  },
  totalRecords(state) {
    return state.totalRecords
  },
  nextPageLink(state) {
    return state.nextPageLink
  },
  prevPageLink(state) {
    return state.prevPageLink
  },
  childrenResponse: (state) => (nextUrl) => {
    return state.childrenResponse[nextUrl] || null
  },
  loading(state) {
    return state.loading
  },
  loaded(state) {
    return state.loaded
  },
  loadingError(state) {
    return state.loadingError
  },
  loadedAll(state) {
    return state.loaded && state.hasMore === false
  },
  uploading(state) {
    return state.uploading
  },
  uploaded(state) {
    return state.uploaded
  },
  uploadTrayOpen(state) {
    return state.uploadTrayOpen
  },
  getUploadingElement: (state) => (id) => {
    return state.uploading.find((item) => item.id === id)
  },
  setAssetProperty(state, { asset, key, value }) {
    asset[key] = value
  },
  getResourcesById: (state) => (id) => {
    let base = state.resources.filter((row) => row.asset_id === id)

    // This deduplicates any duplicated keys in the resource array
    // which fixes a cache bug where older keys may still be in the
    // array for resources we already have updated values for
    let deduped = {}
    base.forEach((row) => {
      deduped[row.key] = row
    })

    let ret = []
    Object.keys(deduped).forEach((key) => {
      ret.push(deduped[key])
    })
    return ret
  },
  getAssetById: (state) => (id) => {
    let found = state.expanded.find((asset) => asset.id === id)

    if (found === undefined) return null
    return found
  },
  getAssetSimpleById: (state) => (id) => {
    let found = state.items.find((asset) => asset.id === id)

    if (found === undefined) return null
    return found
  },
  getAssetsByIds: (state) => (ids) => {
    let found = []

    ids.forEach((id) => {
      let item = findInListByKey(state.items, 'id', id)
      if (item !== null) {
        found.push(item)
      }
    })

    return found
  },

  getRecentAssetsByType: (state) => (id, type) => {
    let items = findManyInListByKey(state.items, 'project_id', id)

    items = findManyInListByKey(items, 'type', type)

    return items.slice(0, 8) // orderBy(items, ['updated_at'], ['desc']).slice(0, 8)
  },

  getRecentAssetsByProject: (state) => (id) => {
    let list = findManyInListByKey(state.items, 'project_id', id)

    let sliced = orderBy(list, ['updated_at'], ['desc']).slice(0, 8)

    return sliced
  },
  getAssetsByDependsOn: (state) => (id, type) => {
    let list = findManyInListByKey(state.items, 'depends_on_id', id)

    if (type !== undefined) {
      list = findManyInListByKey(list, 'type', type)
    }

    return orderBy(list, ['created_at'], ['desc'])
  }
}

const mutations = {
  setLoading(state) {
    state.loading = true
    state.loadingError = false
  },
  setLoaded(state) {
    state.loading = false
    state.loadingError = false
    state.loaded = true
  },
  setLoadingError(state) {
    state.loading = false
    state.loadingError = true
  },
  updateFolderInTree(state, { newFolderData, parentFolderId = null }) {
    /**
     * Recursively walk down the passed tree and add the object
     * to the proper place in the tree
     * @param {Object} existingFolderTree - The existing folder tree to recursively walk down
     * @returns {Object} - The updated folder tree with the new folder added
     */
    function walkTree(existingFolderTree) {
      if (newFolderData.id === existingFolderTree.id) {
        // This is where we want to add/update the new data
        // while still maintaining the existing data

        // First we update the basic properties
        existingFolderTree.expanded = newFolderData.expanded
        existingFolderTree.nextLink = newFolderData.nextLink
        if (newFolderData.label) existingFolderTree.label = newFolderData.label
        existingFolderTree.seen = newFolderData.seen // Once seen we don't ever want to unsee it
          ? newFolderData.seen
          : existingFolderTree.seen

        // Should perform these operations:
        // 1. Add new children with a blank child array
        // 2. Update existing labels with the new label, while maintaining the existing children
        // 3. In the case we passed parentFolderId, we may not know where in the tree
        // it belongs, so we place it based on this parentID (useful for locally loading the tree)
        for (const child of newFolderData.children) {
          // Try to find the child in the tree
          const existingChild = findInListByKey(
            existingFolderTree.children,
            'id',
            child.id
          )
          if (existingChild === null) {
            // If the child doesn't exist, add it (operation 1)
            existingFolderTree.children.push(
              generateFolderTreeStructure({
                id: child.id,
                label: child.label,
                expanded: child.expanded,
                permissions: child.permissions,
                isLocked: child.isLocked
              })
            )
          } else {
            // If the child does exists, just update the label (operation 2)
            existingChild.label = child.label
          }
        }
      } else if (existingFolderTree.id === parentFolderId) {
        // This is the parent folder, so we need to update this folder with the new child (Operation 3)
        // First check if it already exists
        const existingChild = findInListByKey(
          existingFolderTree.children,
          'id',
          newFolderData.id
        )
        if (existingChild === null) {
          // If the child doesn't exist, add it
          existingFolderTree.children.push(
            generateFolderTreeStructure({
              id: newFolderData.id,
              label: newFolderData.label,
              expanded: newFolderData.expanded,
              permissions: newFolderData.permissions,
              isLocked: newFolderData.isLocked,
              children:
                // Sort the children by label like we do below,
                // doing it here prevents the list from re-sorting on next update
                newFolderData.children.length > 0
                  ? orderBy(newFolderData.children, ['label'], ['asc'])
                  : []
            })
          )
        } else {
          // If the child does exists, update the label and children
          existingChild.label = newFolderData.label

          // Only update the children if the old data
          // is missing any of the new data
          if (
            !newFolderData.children.every((child) =>
              existingChild.children.some(
                (newChild) => newChild.id === child.id
              )
            )
          ) {
            existingChild.children = orderBy(
              newFolderData.children,
              ['label'],
              ['asc']
            )
          }
        }
      } else {
        // This is not the folder we want to add the data to,
        // so we need to recursively walk down the tree
        if (!('children' in existingFolderTree)) {
          // Fail safe if the parent doesn't have children yet
          existingFolderTree.children = []
        }

        // Recurse down the tree
        for (let child of existingFolderTree.children) {
          walkTree(child)
        }
      }

      // Sort the children by label
      existingFolderTree.children = orderBy(
        existingFolderTree.children,
        ['label'],
        ['asc']
      )

      return existingFolderTree
    }

    // We shouldn't modify the state in the store until we are done modifying the tree
    const stateTree = cloneDeep(state.folderTree)
    // BUG: Caching the tree is causing a lot of issues like duplicate
    // folders and permissions getting out of sync
    // const folderTreeLocalStorage = JSON.parse(
    //   localStorage.getItem('folderTree')
    // )
    const folderTreeLocalStorage = null

    // Do we have an existing tree? If not, we use the passed data
    // as the top level of the tree as it should be the root folder
    let existingTree =
      stateTree == null ||
      Object.keys(stateTree).length === 0 ||
      newFolderData.id === stateTree.id
        ? newFolderData
        : stateTree

    // Now lets check if we have a locally stored tree
    // that matches our root folder to use (this results
    // in project switching clearing the tree, but within
    // the same project the tree is preserved)
    existingTree =
      folderTreeLocalStorage == null ||
      folderTreeLocalStorage.id !== newFolderData.id
        ? existingTree
        : folderTreeLocalStorage

    state.folderTree = walkTree(existingTree)

    localStorage.setItem('folderTree', JSON.stringify(state.folderTree))
  },
  removeFolderFromTree(state, { parentId, folderId }) {
    // Walk down the tree until we find the parent, then delete the child (the folderId)
    function walkTree(existingFolderTree) {
      if (parentId === existingFolderTree.id) {
        // This is the parent of the child we want to delete, so check its children
        const indexToDelete = existingFolderTree.children.findIndex(
          (child) => child.id === folderId
        )
        if (indexToDelete !== -1) {
          existingFolderTree.children.splice(indexToDelete, 1)
        }
        // Recurse down the tree
      } else if (existingFolderTree.children) {
        for (let child of existingFolderTree.children) {
          walkTree(child)
        }
      }
    }

    const folderTree = cloneDeep(state.folderTree)
    walkTree(folderTree)
    state.folderTree = folderTree
    localStorage.setItem('folderTree', JSON.stringify(state.folderTree))
  },
  resetFolderTree(currentState) {
    currentState.folderTree = state().folderTree
  },
  resetSmartFolders(currentState) {
    currentState.smartFolders = state().smartFolders
  },

  addFolder(state, payload) {
    let folder = payload.folder

    if (folder === null) return

    let collection = 'folders'
    if (payload.collection !== undefined) {
      collection = payload.collection
    }
    state[collection] = updateInListByKey(
      state[collection],
      'id',
      folder.id,
      folder
    )
  },
  addItem(state, item) {
    state.items = updateInListByKey(state.items, 'id', item.id, item)

    // Remove uploading map if the upload_id matches something in progress
    var index = state.uploading.findIndex((e) => e.id === item.upload_id)
    if (index >= 0) state.uploading.splice(index, 1)

    if (!state.seen.includes(item.id)) {
      state.seen.push(item.id)
    }
  },
  addData(state, payload) {
    let data = payload.data
    let length = data.length

    for (let index = 0; index < length; index++) {
      let value = data[index].id
      state.items = updateInListByKey(state.items, 'id', value, data[index])
      if (!state.seen.includes(value)) {
        state.seen.push(value)
      }
    }
  },
  setHasMore(state, more) {
    state.hasMore = more
  },
  setTotalRecords(state, total) {
    state.totalRecords = total
  },
  setNextPageLink(state, url) {
    state.nextPageLink = url
  },
  setPrevPageLink(state, url) {
    state.prevPageLink = url
  },
  setChildrenResponse(state, { nextUrl, payload }) {
    state.childrenResponse[nextUrl] = payload
  },
  removeChildrenResponse(state, nextUrl) {
    delete state.childrenResponse[nextUrl]
  },
  clearChildrenResponses(state) {
    state.childrenResponse = {}
  },

  clear(state) {
    state.active = null
    state.folderTreeSelected = null
    state.currentDragAsset = null
    state.folderTree = {}
    state.folders = []
    state.smartFolders = []
    state.searchFolders = []
    state.items = []
    state.seen = []
    state.hasMore = false
    state.loaded = false
    state.loading = false
    state.loadingError = false
    state.uploading = []
  },

  setActive(state, group) {
    state.active = group
    state.folderTreeSelected = group
  },

  setFolderTreeSelected(state, folderId) {
    state.folderTreeSelected = folderId
  },

  setCurrentDragAsset(state, asset) {
    state.currentDragAsset = asset
  },

  addUploadingMap(state, { uploadElement }) {
    state.uploading.push(uploadElement)
  },
  addUploadedMap(state, { uploadElement }) {
    state.uploaded.push(uploadElement)
  },
  removeUploadedMap(state, { uploadElement }) {
    var index = state.uploaded.findIndex((e) => e.id === uploadElement.id)
    if (index >= 0) state.uploaded.splice(index, 1)
  },

  removeUploadingMap(state, { uploadElement }) {
    var index = state.uploading.findIndex((e) => e.id === uploadElement.id)
    if (index >= 0) state.uploading.splice(index, 1)
  },

  setUploadElementProgress(state, { uploadElement, progress }) {
    uploadElement.progress = progress
  },
  setUploadElementState(state, { uploadElement, uploadState }) {
    uploadElement.uploadState = uploadState
  },
  setUploadElementAbortUpload(state, { uploadElement, abortUpload }) {
    uploadElement.abortUpload = abortUpload
  },
  setUploadElementShouldCancelUpload(
    state,
    { uploadElement, shouldCancelUpload }
  ) {
    uploadElement.shouldCancelUpload = shouldCancelUpload
  },
  toggleUploadTrayOpen(state) {
    state.uploadTrayOpen = !state.uploadTrayOpen
  },

  updateAssetLock(state, { asset, lock }) {
    var item = state.items.find((e) => e.id === asset.id)
    if (item !== undefined) {
      item.is_locked = lock
    }

    // messy but quick
    var itemE = state.expanded.find((e) => e.id === asset.id)
    if (itemE !== undefined) {
      itemE.is_locked = lock
    }

    var itemF = state.folders.find((e) => e.id === asset.id)
    if (itemF !== undefined) {
      itemF.is_locked = lock
    }
  },

  renameAsset(state, { name, asset }) {
    var item = state.items.find((e) => e.id === asset.id)
    if (item !== undefined) {
      item.name = name
    }

    // messy but quick
    var itemE = state.expanded.find((e) => e.id === asset.id)
    if (itemE !== undefined) {
      itemE.name = name
    }
  },

  moveAssets(state, { assets, folderId }) {
    for (const asset of assets) {
      var index = state.items.findIndex((e) => e.id === asset.id)
      if (index === -1) continue // We moved an asset from the folder tree that isn't loaded yet

      let updatedAsset = state.items[index]
      updatedAsset.parent_id = folderId
      state.items.splice(index, 1, updatedAsset)
    }
  },

  deleteAssets(state, { assets }) {
    assets.forEach((asset) => {
      var index = state.items.findIndex((e) => e.id === asset.id)
      if (index > -1) state.items.splice(index, 1)

      // Also remove from seen
      var i = state.seen.findIndex((id) => id === asset.id)
      if (i > -1) state.seen.splice(i, 1)

      // Also remove from expanded
      var expanded = state.expanded.find((e) => e.id === asset.id)
      if (expanded !== undefined) expanded.status = 'deleted'
    })
  },

  addAsset(state, item) {
    state.expanded = updateInListByKey(state.expanded, 'id', item.id, item)
    if (!state.expandedSeen.includes(item.id)) {
      state.expandedSeen.push(item.id)
    }
  },

  addResource(state, { id, item }) {
    item.asset_id = id
    state.resources = updateInListByKey(state.resources, 'id', item.id, item)
  },

  updateAssetSettings(state, { id, path, value }) {
    let item = findInListByKey(state.expanded, 'id', id)
    if (item) {
      item.settings[path] = value
      state.expanded = updateInListByKey(state.expanded, 'id', item.id, item)
    }
  },

  // increment and decrement the comment count of assets
  manageCommentCount(state, { assetId, type, by }) {
    const index = state.items.findIndex((asset) => {
      return asset.id === assetId
    })

    if (index === -1) return

    state.items[index].comment_count =
      type === 'increment'
        ? state.items[index].comment_count + by
        : state.items[index].comment_count - by
  }
}

const actions = {
  setLoaded({ commit }) {
    commit('setLoaded')
  },

  setActive({ commit }, { group }) {
    commit('setActive', group)
  },

  setFolderTreeSelected({ commit }, folderId) {
    commit('setFolderTreeSelected', folderId)
  },

  setCurrentDragAsset({ commit }, asset) {
    commit('setCurrentDragAsset', asset)
  },

  clearCurrentDragAsset({ commit }) {
    commit('setCurrentDragAsset', null)
  },

  clear({ commit }) {
    commit('clear')
  },

  setLoading({ commit, state }) {
    commit('setLoading', state)
  },

  updateAssetFromData({ commit }, { item }) {
    commit('addAsset', item)
  },

  softLoad({ state, dispatch }, { project }) {
    if (state.loaded === true || state.loading === true) return
    dispatch('loadRootFolder', { project })
  },

  reload({ dispatch }, { project }) {
    dispatch('clear').then(() => {
      dispatch('loadRootFolder', { project })
    })
  },

  loadRecentItems({ commit, dispatch }, { project }) {
    // Base Api call
    let uri = 'api/assets-browser/recents'
    let options = { params: { project_id: project.id } }
    commit('setLoading')
    return api
      .rawApiGetCall(uri, options)
      .then((response) => {
        dispatch('handleReturn', { data: response.data, shallow: false })
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  loadRootFolder({ commit, dispatch }, { project, forceActive }) {
    // Base Api call
    let uri = 'api/assets-browser'
    let options = { params: { project_id: project.id } }
    commit('setLoading')
    return api
      .rawApiGetCall(uri, options)
      .then(async (response) => {
        await dispatch('handleReturnFolder', {
          data: response.data,
          shallow: false,
          setActive: forceActive || false
        })
        dispatch('loadSmartFolders', { project })
        dispatch('loadSearchFolders')
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  loadSmartFolders({ commit, dispatch, getters }, { project = null }) {
    const rootFolderId = getters.rootFolder
      ? getters.rootFolder.id
      : project
      ? project.is_entry_folder_root === true
        ? project.entry_folder_uuid
        : null
      : null

    if (rootFolderId === null) return

    let uri = `api/assets-browser/${rootFolderId}/smart-folders`
    commit('setLoading')
    return api
      .rawApiGetCall(uri)
      .then((response) => {
        dispatch('handleSmartFolderReturn', { data: response.data })
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },
  loadSearchFolders({ commit, dispatch, getters }) {
    const rootFolder = getters.rootFolder
    if (!rootFolder) return

    let uri = `api/assets-browser/${rootFolder.id}/search-folders`
    commit('setLoading')
    return api
      .rawApiGetCall(uri)
      .then((response) => {
        dispatch('handleSearchFolderReturn', { data: response.data })
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  newComposition({ commit }, { project, parent, name }) {
    return api.newComposition(project, parent, name).then((ret) => {
      let data = ret.data
      let payload = { data: [data] }
      commit('addData', payload)

      return data
    })
  },

  newPreviz({ commit }, { project, parent, name }) {
    // let name = 'Untitled Previz'

    return api.newPreviz(project, parent, name).then((ret) => {
      let data = ret.data
      let payload = { data: [data] }
      commit('addData', payload)

      return data
    })
  },

  newFolder({ dispatch, commit }, { project, parent, name }) {
    return api.newFolder(project, parent, name).then((ret) => {
      let data = ret.data
      let payload = { data: [data] }
      commit('addData', payload)
      commit('updateFolderInTree', {
        newFolderData: generateFolderTreeStructure({
          id: data.id,
          label: data.name,
          expanded: false,
          permissions: data.permissions,
          isLocked: data.is_locked
        }),
        parentFolderId: parent
      })

      // Now refresh the parent folder from the server
      dispatch('loadFolder', { folderId: parent })

      return data
    })
  },

  newFolderAndMove({ commit, dispatch }, { project, parent, name, assets }) {
    return api.newFolder(project, parent, name).then((ret) => {
      let data = ret.data
      let payload = { data: [data] }
      commit('addData', payload)

      let id = payload.data[0].id
      dispatch('move', { assets, folderId: id })
    })
  },

  newSequenceItem({ commit }, { sceneId, name }) {
    return api
      .newSequenceItem(sceneId, name)
      .then((ret) => {
        let data = ret.data
        let payload = { data: [data] }
        commit('addData', payload)
        return data
      })
      .catch(() => {
        return false
      })
  },

  newAssetItem({ commit }, { project, parent, name, type }) {
    return api
      .newAssetItem(project, parent, name, type)
      .then((ret) => {
        let data = ret.data
        let payload = { data: [data] }
        commit('addData', payload)

        return data
      })
      .catch(() => {
        return false
      })
  },

  addResource({ commit }, { id, item }) {
    commit('addResource', { id: id, item: item })
  },

  loadAsset({ commit, state, dispatch }, { id, force }) {
    if (force === undefined) force = false

    // Early return check
    if (!force) {
      if (state.expandedSeen.includes(id)) {
        const existingAsset = state.expanded.find((asset) => asset.id === id)
        if (
          existingAsset &&
          existingAsset.status !== 'pending' &&
          existingAsset.status !== 'preparing'
        ) {
          // Always check the API for assets that are pending or preparing
          // as we know they still need to be updated
          return existingAsset
        }
      }
    }

    let uri = 'api/assets/' + id

    commit('setLoading')
    return api
      .rawApiGetCall(uri)
      .then((response) => {
        let data = response.data.data

        if (data.resources) {
          if (
            data.resources.data !== undefined &&
            data.resources.data.length > 0
          ) {
            data.resources.data.forEach((row) => {
              let resource = row.data
              commit('addResource', { id: data.id, item: resource })
            })
          }

          // remove resources from the return data to prevent mis-accessing it
          delete data.resources
        }

        commit('addAsset', data)

        let ancestorsLink = findInListByKey(
          response.data.links,
          'rel',
          'assets-browser.ancestors'
        )
        let group = data.id
        let parentId = data.parent_id
        if (ancestorsLink) {
          // dispatch('setActive', { group: parentId })
          dispatch('loadAncestorsIfNeeded', { group, parentId, ancestorsLink })
        }

        return data
      })
      .catch((error) => {
        commit('setLoadingError')
        throw error
      })
  },

  /**
   * Adds the passed folder to the folder tree, prepares folder data
   * to pass to mutation which actually does the merging
   * If the folderTree doesn't exist, we populate it with the root folder
   * @param {String} folderId - The id of the folder to add to the tree
   */
  async updateFolderInTree({ commit, getters, rootGetters }, folderId) {
    async function updateChildrenFromServer(newDataToAdd) {
      // When there is no next link, we are at the first page, which
      // won't have a query param, so we need to manually construct that
      const url =
        newDataToAdd.nextLink === ''
          ? `${process.env.VUE_APP_SERVER_API_BASE}api/assets-browser/${folderId}/children`
          : newDataToAdd.nextLink

      // Does a response already exist in our local cache?
      // If not, we need to fetch it from the server
      const existingResponse = getters.childrenResponse(url)
      const response = existingResponse || (await api.rawApiGetCall(url))

      // Set the next children URL to hit
      const nextLink = findInListByKey(
        response.data.pagination.links,
        'rel',
        'pagination.next'
      )

      if (nextLink == null) {
        newDataToAdd.nextLink = ''
      } else if (nextLink.url !== newDataToAdd.nextLink) {
        newDataToAdd.nextLink = nextLink.url
      } else {
        newDataToAdd.nextLink = ''
      }

      // Transform the API response into a structure the mutator is expecting
      // while maintaining any existing children
      const existingChildren =
        getters.getFolderInTreeForId(folderId) != null
          ? getters.getFolderInTreeForId(folderId).children
          : []

      for (const item of response.data.data) {
        if (item.data.type === 'folder') {
          // Find any existing children that might be in the tree
          const existingChild = existingChildren.find(
            (child) => child.id === item.data.id
          )
          // Merge the existing children in with the new children
          newDataToAdd.children.push(
            existingChild
              ? {
                  ...existingChild,
                  label: item.data.name
                }
              : generateFolderTreeStructure({
                  id: item.data.id,
                  label: item.data.name,
                  expanded: false,
                  isLocked: item.data.is_locked,
                  permissions: item.data.permissions
                })
          )
        }
      }

      return newDataToAdd
    }

    function addChildrenToTree(folderId) {
      getters.getFoldersByParentId(folderId).forEach((folder) => {
        // Don't add smart folders to the tree
        if (folder.is_smart_folder) return
        if (folder.is_search_folder) return

        const folderTree = generateFolderTreeStructure({
          id: folder.id,
          label: folder.name,
          isLocked: folder.is_locked,
          permissions: folder.permissions
        })

        // The child won't exist in the tree yet, so
        // we need to also pass the parentId
        commit('updateFolderInTree', {
          newFolderData: folderTree,
          parentFolderId: folderId
        })

        addChildrenToTree(folder.id)
      })
    }

    // Fail safe for when the root folder is not loaded yet
    const rootFolder = getters.rootFolder
    if (rootFolder == null) return

    // Tries to see if we already have local data for the folder
    // so we can use the name
    const existingFolder = getters.getFolderById(folderId)

    // Don't add smart folders to the tree
    if (
      existingFolder &&
      (existingFolder.is_smart_folder || existingFolder.is_search_folder)
    )
      return

    let newDataToAdd = generateFolderTreeStructure(
      existingFolder != null && Object.keys(existingFolder).length > 0
        ? {
            id: existingFolder.id,
            label: existingFolder.name,
            isLocked: existingFolder.is_locked,
            permissions: existingFolder.permissions
          }
        : {
            id: folderId
          }
    )

    // Has the tree been constructed yet? If not, populate it with the root
    if (Object.keys(getters.folderTree).length === 0) {
      // We have a root, but no tree. At this point we might
      // have local children folders already in the state
      // so we need to try to construct a tree locally with the
      // information we have

      // Add the root folder to the tree
      const rootFolderData = generateFolderTreeStructure({
        id: rootFolder.id,
        label: rootFolder.name,
        isLocked: rootFolder.is_locked,
        permissions: rootFolder.permissions
      })
      commit('updateFolderInTree', { newFolderData: rootFolderData })

      // Add each child recursively to the tree using local data
      addChildrenToTree(rootFolderData.id)
    }

    // Make the API request to the children endpoint to get the children
    // Currently we are mimicking the main folder design of just keep requesting the next page
    // until all folders are loaded, but we probably want to change this to actually lazy load
    try {
      do {
        newDataToAdd = await updateChildrenFromServer(newDataToAdd)
      } while (newDataToAdd.nextLink !== '')
    } catch (error) {
      // The error is being swallowed but it's usually a 403 if the
      // user is a guest. In that case, just add the data we know/can below
      console.error(error)
    }

    const guestEntryUuid = rootGetters['project/guestEntryUuid']

    // Call the mutator, which runs the merge function
    commit('updateFolderInTree', {
      newFolderData: newDataToAdd,
      // For guests, they can only access one folder, so we manually set
      // the parent to be the root (even if that's not accurate) so the folder
      // tree will be constructed correctly
      parentFolderId: guestEntryUuid === newDataToAdd.id ? rootFolder.id : null
    })
  },

  /**
   * Expands the passed folder in the tree, loading children if needed
   * @param {String} folderId - The id of the folder to expand
   * @param {Boolean} collapse - If true, we force collapse the folder
   * @param {Boolean} expand - If true, we force expand the folder
   * Note that if both the collapse and expand params are false, we will
   * just invert the current expanded state
   */
  async setFolderTreeExpanded(
    { commit, dispatch, getters },
    { folderId, collapse = false, expand = false }
  ) {
    // Get the folder and either invert the expanded state,
    // or set it false if we are collapsing it
    let folder = cloneDeep(getters.getFolderInTreeForId(folderId))
    const expanded = collapse ? false : expand ? true : !folder.expanded

    // If there are no children, let's check for children
    if (folder.children.length === 0) {
      await dispatch('updateFolderInTree', folderId)
      folder = cloneDeep(getters.getFolderInTreeForId(folderId))
    }

    folder.expanded = expanded
    folder.seen = true
    commit('updateFolderInTree', { newFolderData: folder })
  },

  setFolderTreeParentsExpanded({ commit, getters }, folderId) {
    // TODO: This isn't very optimized for large trees,
    // as this will iterate through the tree to expand everything,
    // then it will iterate again to load the folder
    // This does seem to only really be an issue when moving from root to
    // a sub folder and it seems to be okay after that

    let treeFolder = cloneDeep(getters.getFolderInTreeForId(folderId))
    let folder = getters.getFolderById(folderId)
    let parentFolderId = folder.parent

    if (!treeFolder) return

    do {
      // Set the folder to expanded and commit to the store
      // Get the parent, set it to expanded, commit to the store
      // repeat until we reach the root
      treeFolder.expanded = true
      commit('updateFolderInTree', { newFolderData: treeFolder })

      treeFolder = cloneDeep(getters.getFolderInTreeForId(parentFolderId))
      folder = getters.getFolderById(parentFolderId)
      parentFolderId = folder.parent
    } while (parentFolderId != null)

    // Set the root folder to expanded
    const rootFolder = getters.rootFolder
    if (rootFolder != null) {
      const rootFolderTree = cloneDeep(
        getters.getFolderInTreeForId(getters.rootFolder.id)
      )
      rootFolderTree.expanded = true
      commit('updateFolderInTree', { newFolderData: rootFolderTree })
    }
  },

  updateFolder({ commit }, { folder }) {
    commit('addFolder', { folder })
  },

  loadChildSequences({ commit, dispatch }, { id }) {
    let uri = 'api/assets-browser/' + id + '/sequences'

    commit('setLoading')
    return api
      .rawApiGetCall(uri)
      .then((response) => {
        let data = response.data.data

        data.forEach((row) => {
          let sequence = row.data
          commit('addItem', sequence)
        })
        return data
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  loadFolder({ commit, dispatch }, { folderId, shallow }) {
    if (shallow === undefined) shallow = false
    let uri = 'api/assets-browser/' + folderId
    commit('setLoading')
    return api
      .rawApiGetCall(uri)
      .then((response) => {
        dispatch('handleReturnFolder', {
          data: response.data,
          shallow: shallow
        })
      })
      .catch((error) => {
        commit('setLoadingError')
        throw error
      })
  },

  search({ commit, dispatch }, { projectId, query, folderName }) {
    let uri = 'api/assets-browser/search'
    let options = {
      project_id: projectId,
      query: query,
      folder_name: folderName
    }
    commit('setLoading')

    return api
      .rawApiPostCall(uri, options)
      .then((response) => {
        return response
      })
      .catch((err) => {
        commit('setLoadingError')
        throw err
      })
  },

  bulkDownloadAssets({ commit, dispatch }, { assets }) {
    let ids = []
    assets.forEach((asset) => {
      ids.push(asset.id)
    })
    let uri = 'api/assets-download/' + ids.join(',')

    return api
      .rawApiGetCall(uri)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        throw error
      })
  },

  downloadAsset({ commit, dispatch }, { id }) {
    let uri = 'api/assets-browser/' + id + '/download'

    commit('setLoading')
    return api
      .rawApiGetCall(uri)
      .then((response) => {})
      .catch(() => {
        commit('setLoadingError')
      })
  },

  loadNext({ dispatch, commit, state, getters }, { group }) {
    let uri = state.nextPageLink
    // Does a response already exist in our local cache?
    // If not, we need to fetch it from the server
    const existingResponse = getters.childrenResponse(uri)

    if (existingResponse !== null) {
      return dispatch('handleReturn', { data: existingResponse.data, group })
    } else {
      return api.rawApiGetCall(uri).then(async (response) => {
        // Cache the results for the tree to use
        commit('setChildrenResponse', {
          nextUrl: uri,
          payload: response
        })
        await dispatch('handleReturn', { data: response.data, group })
      })
    }
  },

  async handleReturnFolder(
    { commit, dispatch, getters },
    { data, shallow, setActive, collection }
  ) {
    let payload = {
      folder: data.data
    }
    let group = data.data.id
    let parentId = data.data.parent

    if (shallow !== true && setActive !== false) {
      dispatch('setActive', { group: group })
    }

    if (collection !== undefined) {
      payload.collection = collection
    }

    commit('addFolder', payload)

    // Root folder doesn't always call it's children,
    // so force it to load them
    if (getters.rootFolder && data.data.id === getters.rootFolder.id) {
      commit(
        'setNextPageLink',
        `${process.env.VUE_APP_SERVER_API_BASE}api/assets-browser/${data.data.id}/children`
      )
      await dispatch('loadNext', { group })
    }

    if (data.data.is_smart_folder) {
      commit(
        'addFolder',
        Object.assign({}, cloneDeep(payload), { collection: 'smartFolders' })
      )
    }

    if (data.data.is_search_folder) {
      commit(
        'addFolder',
        Object.assign({}, cloneDeep(payload), { collection: 'searchFolders' })
      )
    }

    // Updates the smart folder parts of the store if the returned
    // folder is one
    if (payload.folder.is_smart_folder) {
      if (!getters.getSmartFolder(group)) {
        // Oops, we don't even have the folder in the store, let's grab it
        await dispatch('loadAsset', { id: group })
      }

      dispatch('updateSmartFolder', {
        folder: getters.getSmartFolder(group)
      })
    }

    if (payload.folder.is_search_folder) {
      if (!getters.getSearchFolder(group)) {
        // Oops, we don't even have the folder in the store, let's grab it
        await dispatch('loadAsset', { id: group })
      }

      dispatch('updateSearchFolder', {
        folder: getters.getSearchFolder(group)
      })
    }

    let itemsLink = findInListByKey(
      data.links,
      'rel',
      'assets-browser.children'
    )
    if (itemsLink && shallow !== true) {
      commit('setNextPageLink', itemsLink.url)
      await dispatch('loadNext', { group: group })
    }

    let ancestorsLink = findInListByKey(
      data.links,
      'rel',
      'assets-browser.ancestors'
    )
    if (ancestorsLink && shallow !== true) {
      // We wait here so the folder tree has everything it needs to
      // construct itself when called
      await dispatch('loadAncestorsIfNeeded', {
        group,
        parentId,
        ancestorsLink
      })
    }

    // Update the tree with the new folder
    dispatch('updateFolderInTree', group)
  },

  loadAncestorsIfNeeded(
    { state, commit, dispatch },
    { group, parentId, ancestorsLink }
  ) {
    // Does this parent exist in our tree?
    let parent = findInListByKey(state.folders, 'id', parentId)
    if (parent) return

    // we don't have this parent, load all the folder's ancestors
    commit('setLoading')
    let url = ancestorsLink.url
    return api
      .rawApiGetCall(url)
      .then((response) => {
        response.data.data.forEach((row) => {
          dispatch('handleReturnFolder', { data: row, shallow: true })
        })
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  async handleReturn({ commit, dispatch, getters }, { data, group }) {
    if (data === undefined) {
      console.error(
        'No data passed, did you forget to update the payload for axios?'
      )
      return
    }

    const smartFolder = getters.getSmartFolder(group)
    const smartFolderId = smartFolder ? smartFolder.id : null

    const searchFolder = getters.getSearchFolder(group)
    const searchFolderId = searchFolder ? searchFolder.id : null

    let dataCleaned = []
    data.data.forEach((row) => {
      // Update the smart folder set with the smartFolderId
      // if this asset is part of a smart folder
      row.data.smart_folders = getters.getSmartFoldersForAsset(row.data.id)
      if (smartFolderId) row.data.smart_folders.add(smartFolderId)

      row.data.search_folders = getters.getSearchFoldersForAsset(row.data.id)
      if (searchFolderId) {
        row.data.search_folders.add(searchFolderId)
      }

      dataCleaned.push(row.data)
    })

    let hasMore = false
    data.pagination.links.forEach((row) => {
      if (row.rel === 'pagination.next') {
        commit('setNextPageLink', row.url)
        hasMore = true
      }
      if (row.rel === 'pagination.previous') {
        commit('setPrevPageLink', row.url)
      }
    })

    let totalRecords = data.pagination.total_count

    commit('setHasMore', hasMore)
    let payload = { data: dataCleaned }
    commit('addData', payload)
    commit('setTotalRecords', totalRecords)

    if (hasMore) {
      await dispatch('loadNext', { group: group })
    } else {
      commit('setLoaded')
    }
  },

  handleSmartFolderReturn({ commit, dispatch }, { data }) {
    data.data.forEach((folder) => {
      commit('addFolder', { collection: 'smartFolders', folder: folder.data })
    })

    // Just keep calling the next page until we're done
    const nextLink = findInListByKey(
      data.pagination.links,
      'rel',
      'pagination.next'
    )
    if (nextLink) {
      api.rawApiGetCall(nextLink.url).then((response) => {
        dispatch('handleSmartFolderReturn', { data: response.data })
      })
    }
  },

  handleSearchFolderReturn({ commit, dispatch }, { data }) {
    data.data.forEach((folder) => {
      commit('addFolder', { collection: 'searchFolders', folder: folder.data })
    })

    // Just keep calling the next page until we're done
    // Add pagination
    const nextLink = findInListByKey(
      data.pagination.links,
      'rel',
      'pagination.next'
    )
    if (nextLink) {
      api.rawApiGetCall(nextLink.url).then((response) => {
        dispatch('handleSearchFolderReturn', { data: response.data })
      })
    }
  },

  updateSmartFolder({ commit, dispatch }, { folder }) {
    // Mimic the API response as if this was requested
    // via the API endpoint (cheating
    // so we can run the same action on the same data)
    dispatch('handleSmartFolderReturn', {
      data: {
        data: [{ data: folder }],
        pagination: { links: [] }
      }
    })
  },

  updateSearchFolder({ commit, dispatch }, { folder }) {
    // Mimic the API response as if this was requested
    // via the API endpoint (cheating
    // so we can run the same action on the same data)
    dispatch('handleSearchFolderReturn', {
      data: {
        data: [{ data: folder }],
        pagination: { links: [] }
      }
    })
  },

  /**
   * Makes a series of batch calls to the pre-check endpoint, prior to uploading
   *
   * @param {Array} payload - the files to pre-check doesn't have to be batched, but
   * must follow the format of the payload below
   * @param {File} payload.file - the file to upload
   * @param {string} payload.path - the path to upload to
   * @param {string} payload.uploadId - the upload id to use for this file
   * @param {string} parentFolderId - the parent folder to upload to (note
   * that the file path names may have additional directories in their path name too)
   * @return {Array} - The results of the pre-checks
   */
  async preCheckAssetUpload({ commit, dispatch }, { payload, parentFolderId }) {
    const combinedResults = []
    for (let i = 0; i < payload.length; i += 50) {
      const batch = payload.slice(i, i + 50)

      const batchPayload = batch.map((file) => {
        const extensions = this.state.fileTypes.fileTypes.data
        const fileExtension = file.file.name.split('.').pop().toLowerCase()
        const type = getAssetType(file.file, extensions, fileExtension)

        return {
          upload_id: file.uploadId,
          name: file.file.name,
          path: file.path,
          parent: parentFolderId,
          type: type
        }
      })

      const response = await api.rawApiPostCall(
        'api/assets/upload-pre-check',
        batchPayload
      )

      if (response.status === 200) {
        combinedResults.push(...response.data)
      }
    }

    return combinedResults
  },

  /**
   * Uploads an asset directly
   *
   * If the asset is a supported "quick" asset - ie. image
   * will split it into 2 operations, first creating a stub
   * upload, to allow early preview, and a secondary real upload
   *
   * @param {object} project - the project to upload to
   * @param {File} file - the file to upload
   * @param {string} parentId - the parent folder to upload to
   * @param {object} extra - extra data to pass to the upload
   * @param {AssetConflictAction} conflictAction - the conflict action to use
   *
   */
  async upload(
    { dispatch, commit },
    {
      project,
      file,
      parentId,
      extra,
      conflictAction = AssetConflictAction.CREATE_NEW_ASSET_WITH_SUFFIX
    }
  ) {
    let path = ''

    if (extra !== undefined && extra.relativePath !== undefined) {
      path = extra.relativePath
    }

    let uploadElement = {
      id:
        file.lastModified +
        ':' +
        file.name +
        ':' +
        file.size +
        ':' +
        Math.floor(Math.random() * 1000),
      name: file.name,
      path: path,
      uploadState: 'uploading',
      progress: 0,
      file: file,
      abortUpload: null,
      shouldCancelUpload: false,
      parent_id: parentId, // For the UI
      type: 'upload', // For the UI
      status: 'uploading', // For the UI
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      clearAfterError: () => {
        commit('removeUploadingMap', { uploadElement })
      }
    }

    commit('addUploadingMap', { uploadElement })

    let options = {
      progress: (progress) => {
        commit('setUploadElementProgress', { uploadElement, progress })
      },
      uploadElement: uploadElement
    }

    const DirectUpload = new PrevizDirectUpload(
      file.size > assetConfig.uploadMultipartBreakpoint
        ? UploadType.MULTIPART
        : UploadType.STANDARD
    )

    DirectUpload.store(file, options)
      .then((response) => {
        // The user canceled the upload, so remove it from the list and abort
        if (response.state === UploadState.CANCELLED) {
          commit('setUploadElementState', {
            uploadElement,
            uploadState: 'aborted'
          })
          commit('removeUploadingMap', { uploadElement })
          return
        }

        let extensions = this.state.fileTypes.fileTypes.data
        const type = getAssetType(file, extensions, response?.extension)
        const fileExtension =
          file.name.substring(
            file.name.lastIndexOf('.') + 1,
            file.name.length
          ) || file.name

        let data = {
          parent_id: parentId,
          project_id: project.id,
          type: type,
          uuid: response.uuid,
          key: response.key,
          name: file.name,
          path: path,
          upload_id: uploadElement.id,
          file_extension: fileExtension,
          asset_versioning_override_id: extra.asset_versioning_override_id,
          conflict_action: conflictAction.name
        }

        return api
          .newAssetFromDirectUpload(data)
          .then((response) => {
            commit('addUploadedMap', { uploadElement })
            commit('removeUploadingMap', { uploadElement })

            commit('setUploadElementState', {
              uploadElement,
              uploadState: 'complete',
              status: 'complete',
              type: 'done'
            })
            commit('addItem', response.data)
            commit('addAsset', response.data)

            // Load the parent to refresh the item count
            dispatch('loadFolder', {
              folderId: parentId
            })

            return response
          })
          .catch((err) => {
            throw err
          })
      })
      .catch((err) => {
        commit('setUploadElementState', {
          uploadElement,
          uploadState: 'error'
        })
        console.error('Direct upload rejected', err)
      })
  },

  cancelUpload({ commit, getters }, { uploadElementId }) {
    commit('setUploadElementShouldCancelUpload', {
      uploadElement: getters.getUploadingElement(uploadElementId),
      shouldCancelUpload: true
    })
  },

  toggleUploadTrayOpen({ commit }) {
    commit('toggleUploadTrayOpen')
  },

  saveVersionWithNewData({ dispatch }, { asset, data }) {
    return api.saveAssetVersionNewData(asset, data).then((returnData) => {
      return returnData
    })
  },

  updateVersionCoverImage({ dispatch }, data) {
    return api.updateVersionCoverImage(data).then((returnData) => {
      return returnData
    })
  },

  updateAssetActiveVersion({ dispatch }, { asset, payload }) {
    return api.updateAssetActiveVersion(asset, payload).then(() => {
      return dispatch('loadAsset', { id: asset, force: true })
    })
  },

  deleteAssetVersion({ dispatch }, { asset, version }) {
    return api.deleteAssetVersion(asset, version).then(() => {
      return dispatch('loadAsset', { id: asset.id, force: true })
    })
  },

  /**
   * Uploads an asset version directly
   */
  uploadVersion({ dispatch }, { file, asset, extra }) {
    return api.uploadAssetVersion(file, asset, extra).then((assetData) => {
      return assetData
      // return dispatch('addItem', { item: assetData }).then(asset => asset)
    })
  },

  renameVersionLabel({ dispatch }, { asset, version, label }) {
    return api.updateAssetVersion(asset, version, { label }).then(() => {
      return dispatch('loadAsset', { id: asset.id, force: true })
    })
  },

  updateAssetSettings({ commit }, { id, settings }) {
    // commit('updateAssetSettings', { id, settings })
    return api.updateAssetSettings(id, settings)
  },

  addItem({ commit }, { item }) {
    commit('addItem', item)
    return item
  },

  update({ commit }, { asset, payload }) {
    return api
      .updateAsset(asset.id, payload)
      .then((response) => {
        let data = response.data
        commit('addAsset', data)
        return true
      })
      .catch(() => {
        return false
      })
  },

  rename({ commit }, { asset, name }) {
    let originalName = asset.name

    return api
      .renameAsset(asset, name)
      .then((response) => {
        commit('renameAsset', { name: response.data.name, asset })

        if (asset.type === 'folder') {
          commit('addFolder', {
            folder: { id: response.data.id, name: response.data.name }
          })

          commit('updateFolderInTree', {
            newFolderData: generateFolderTreeStructure({
              id: asset.id,
              label: name,
              isLocked: asset.is_locked,
              permissions: asset.permissions
            }),
            parentFolderId: asset.parent_id
          })
        }

        return response
      })
      .catch((error) => {
        commit('renameAsset', { name: originalName, asset })
        throw error
      })
  },

  lock({ commit, getters }, { asset, lock }) {
    // Do this immediately to make the UI appear quicker,
    // if the request fails we can revert it
    commit('updateAssetLock', { asset: asset, lock: lock })

    return api
      .lockAsset(asset, lock)
      .then((response) => {
        // To save on extra API calls, we assume the locked status back from the server
        // as been successfully updated on all child elements as expected so we'll
        // just update the tree with all children's locked statuses
        if (asset.type === 'folder') {
          const treeFolderData = getters.getFolderInTreeForId(asset.id)
          treeFolderData.isLocked = response.data.is_locked

          commit('updateFolderInTree', {
            newFolderData: treeFolderData,
            parentFolderId: asset.parent
          })

          getters.getFolderChildrenInTreeForId(asset.id).forEach((childId) => {
            const childFolderData = getters.getFolderInTreeForId(childId)
            childFolderData.isLocked = response.data.is_locked

            commit('updateFolderInTree', {
              newFolderData: childFolderData,
              parentFolderId: getters.getFolderParentInTreeForId(childId).id
            })
          })
        }

        return response
      })
      .catch((error) => {
        commit('updateAssetLock', { asset: asset, lock: !lock })
        throw error
      })
  },

  duplicate({ dispatch, commit }, { asset, destination }) {
    return api
      .duplicateAsset(asset, destination)
      .then((response) => {
        let data = response.data

        dispatch('loadFolder', { folderId: data.parent_id })

        commit('addAsset', data)

        commit('addItem', data)

        return response
      })
      .catch((error) => {
        throw error
      })
  },

  delete({ dispatch, commit }, { assets }) {
    // let originals = assets
    commit('deleteAssets', { assets })
    assets.forEach((asset) => {
      commit('removeFolderFromTree', {
        parentId: asset.parent_id,
        folderId: asset.id
      })
      dispatch('comments/clearAssetsComments', { asset: asset }, { root: true })
    })
    return api
      .deleteAssets(assets)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log(error)
      })
  },

  async move({ dispatch, commit, getters }, { assets, folderId }) {
    const activeFolderAncestors = getters
      .getTreeForFolderId(getters.active)
      .reduce((acc, folder) => {
        acc.push(folder.id)
        return acc
      }, [])

    const validDataSet = []
    let activeFolderParent = null
    assets.forEach((asset) => {
      // This can be used for assets and folders,
      // but we don't know if the asset is loaded yet
      // So we first check if it's loaded, and if not we grab it from the tree
      const assetItem =
        getters.getAssetSimpleById(asset.id) ||
        getters.getFolderInTreeForId(asset.id)
      // The tree doesn't know it's parent without looking it up,
      // so prepare that too
      const parentId =
        assetItem.parent_id || getters.getFolderParentInTreeForId(asset.id).id
      const folderItem =
        getters.getAssetSimpleById(folderId) ||
        getters.getFolderInTreeForId(folderId)
      // Double check the folderId !== the asset.id (ie. it's been dropped on itself),
      // that you aren't moving it into it's own parent, and you aren't moving a
      // parent into one of its children
      if (
        asset.id !== folderId &&
        parentId !== folderId &&
        !getters.getFolderChildrenInTreeForId(asset.id).includes(folderId)
      ) {
        if (activeFolderAncestors.includes(assetItem.id))
          activeFolderParent = parentId
        if (!assetItem.locked && !folderItem.locked) {
          validDataSet.push({ asset: assetItem, parentId: parentId })
        }
      }
    })
    const validAssets = validDataSet.map((item) => item.asset)

    if (validDataSet.length > 0) {
      // TODO: Use the response to set the store state,
      // currently unclear how multi select is currently handled which is why
      // we're not using the response right now
      await api
        .moveAssets(validAssets, folderId)
        .then((response) => {
          // Map all the parents of the assets that were moved, filter out duplicates
          const parentIds = validDataSet
            .map((item) => item.asset.parent_id)
            .filter((value, index, self) => self.indexOf(value) === index)

          // Update each parent folder to refresh the item count
          parentIds.forEach((parentId) => {
            dispatch('loadFolder', { folderId: parentId })
          })
        })
        .catch((error) => {
          console.log(error)
        })

      commit('moveAssets', {
        assets: validAssets,
        folderId
      })

      validDataSet.forEach((assetData) => {
        // Update the tree with each asset
        // First update the new location, then remove the old one
        if (
          (assetData.asset.type && assetData.asset.type === 'folder') ||
          assetData.asset.children
        ) {
          commit('updateFolderInTree', {
            // If we didn't load the asset from the tree,
            // we need to grab it now in order to store the correct data structure
            newFolderData: assetData.asset.children
              ? assetData.asset
              : getters.getFolderInTreeForId(assetData.asset.id),
            parentFolderId: assetData.asset.parent_id
          })
          commit('removeFolderFromTree', {
            parentId: assetData.parentId,
            folderId: assetData.asset.id
          })
        }

        // Moving the active folder causes odd issues with things like
        // the breadcrumb component so we copy Windows Explorer's behavior
        // and move to the parent
        if (activeFolderParent) commit('setActive', activeFolderParent)
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
