/*
  Project module
  ---
  This is the v2 of the 'project' module
  This will contain general project storage
*/

import * as api from '@utils/api.js'
// import filter from 'lodash/filter'
// import split from 'lodash/split'
// import forEach from 'lodash/forEach'
// import includes from 'lodash/includes'
// import union from 'lodash/union'
// import orderBy from 'lodash/orderBy'

const state = {
  data: null,

  loadedOn: null,
  loaded: false,
  loading: false,
  loadingError: false,
  loadedPermissionError: false
}

const getters = {
  project(state) {
    return state.data
  },

  loading(state) {
    return state.loading
  },
  loaded(state) {
    return state.loaded
  },
  loadingError(state) {
    return state.loadingError
  },
  loadedPermissionError(state) {
    return state.loadedPermissionError
  },
  projectRole(state) {
    return state.data.project_role
  },
  isFullProjectMember(state) {
    if (!state.data || !state.data.project_role) {
      return false
    }

    const fullRoles = ['member', 'admin', 'owner']
    return fullRoles.includes(state.data.project_role)
  },
  isLimitedProjectMember(state) {
    if (!state.data || !state.data.project_role) {
      return false
    }

    const limitedRoles = ['guest_collaborator', 'guest_reviewer']
    return limitedRoles.includes(state.data.project_role)
  },

  guestEntryUuid(state) {
    return state.data?.entry_folder_uuid || null
  }
}

const mutations = {
  addData(state, data) {
    state.data = data
  },

  setLoading(state) {
    state.loading = true
    state.loadingError = false
    state.loadedPermissionError = false
  },
  setLoaded(state) {
    state.loading = false
    state.loadingError = false
    state.loadedPermissionError = false
    state.loaded = true
    state.loadedOn = Date.now()
  },
  setLoadingError(state) {
    state.loading = false
    state.loadingError = true
  },
  setLoadedPermissionError(state) {
    state.loading = false
    state.loadingError = true
    state.loadedPermissionError = true
  },
  setProjectCover(state, { id, cover }) {
    for (let index = 0; index < state.data.length; index++) {
      if (state.data[index].id === id) {
        state.data[index].cover = cover
      }
    }
  },

  clear(state) {
    state.data = null
    state.loaded = false
    state.loading = false
    state.loadingError = false
    state.loadedPermissionError = false
  }
}

const actions = {
  clear({ commit }) {
    commit('clear')
  },

  loadProject({ commit, dispatch }, { projectId }) {
    // Clear folder tree
    commit('assets/resetFolderTree', null, { root: true })
    commit('assets/resetSmartFolders', null, { root: true })

    // Base Api call
    let uri = 'api/projects/' + projectId
    commit('setLoading')
    return api
      .rawApiGetCall(uri)
      .then((response) => {
        return dispatch('handleReturn', response.data.data)
      })
      .catch((error) => {
        commit('clear')
        error.status === 403
          ? commit('setLoadedPermissionError')
          : commit('setLoadingError')
      })
  },
  silentLoadProject({ commit, dispatch }, { projectId }) {
    // Base Api call
    let uri = 'api/projects/' + projectId
    commit('setLoading')
    return api
      .rawApiGetCall(uri)
      .then((response) => {
        return dispatch('handleReturn', response.data.data)
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  /**
   * Updates the last accessed for the loaded project
   */
  updateLastAccessed({ commit, dispatch }, { params }) {
    // Base Api call
    let uri = 'api/projects/update-order'

    let payload = params

    commit('setLoading')
    return api.rawApiPostCall(uri, payload)
  },

  setLoading({ commit, state }) {
    commit('setLoading', state)
  },

  handleReturn({ commit, dispatch }, data) {
    commit('addData', data)
    return data
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
